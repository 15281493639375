import { Box, Grid } from '@mui/material';
import {StartSection, OrganizationSection, TypeObraSection} from './components/sections';
import { ObraProperties } from '../../../types/ObraDetails';
import styles from './generalData.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

interface GeneralDataProps {
  obra: ObraProperties;
}

const GeneralData = ({obra}: GeneralDataProps) => {
  const [t] = useTranslation();
  const timestamp = obra.general.fechaUltimaValidacion;
  const formattedDate = timestamp
    ? moment(timestamp).format('DD/MM/YYYY')
    : 'Fecha no disponible';

  const originCecoc = obra.aspectosTecnicos.tiposObras.filter(
    ({ origen }) => origen === 'cecoc'
  );
  const originPhia = obra.aspectosTecnicos.tiposObras.filter(
    ({ origen }) => origen === 'phia'
  );

  return (
    <Box
      sx={{
        pl: 2,
        pr: 2,
        margin: 'auto',
        fontFamily: 'Roboto',
        width: '95%',
      }}
    >
      <Grid
        container
        spacing={8}
        direction="column"
        className={styles.generalDataWrapper}
      >
        {obra.general.fechaUltimaValidacion && (
          <div className={styles.lastUpdate} id='lastUpdate'>{t('details.validationDate')}:
            <span className={styles.lastUpdateDate} id='lastUpdateDate'> {formattedDate}</span>
          </div>
        )}
        <StartSection obra={obra}/>
        <OrganizationSection obra={obra}/>
        <TypeObraSection originCecoc={originCecoc} originPhia={originPhia}/>
        <div className='footer' style={{marginBottom: '75px'}}></div>
      </Grid>
    </Box>
  );
};

export default GeneralData;
